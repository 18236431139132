import React, { useState, useEffect } from "react"

function ScrollText({ text, initialFontSize, className }) {
  const [fontSize, setFontSize] = useState(initialFontSize)

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      const newFontSize = initialFontSize - scrollPosition * 0.05 // Adjust the factor as needed
      setFontSize(newFontSize)
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <div className="scroll-text-container">
      <p
        className={`scroll-text ${className}`}
        style={{
          fontSize: fontSize + "px",
          transition: "font-size 0.25s ease",
        }}
      >
        {text}
      </p>
    </div>
  )
}

export default ScrollText
