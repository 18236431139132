import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import ScrollText from "./ScrollText"

const Header = ({ siteTitle }) => {
  const [opacity, setOpacity] = useState(1)

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      console.log(scrollPosition, scrollPosition * 0.01)
      const newOpacity = opacity - scrollPosition * 0.002 // Adjust the factor as needed
      setOpacity(newOpacity)
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0) // Scroll to the top when the component mounts
  }, [])

  return (
    <ul
      className="container"
      style={{ opacity, transition: "opacity 0.25s ease" }}
    >
      <li>
        {/* <ScrollText text="Joshua Jones" initialFontSize={96} /> */}
        <span className="title joshuaJones">Joshua Jones</span>
      </li>
      <li className="nav-container">
        <li>
          <Link href="#about" className="link">
            <ScrollText text="About" initialFontSize={32} className="link" />
          </Link>
        </li>
        <li>
          <Link href="#code" className="link">
            <ScrollText text="Code" initialFontSize={32} className="link" />
          </Link>
        </li>
        <li>
          <Link href="#contact" className="link">
            <ScrollText text="Contact" initialFontSize={32} className="link" />
          </Link>
        </li>
      </li>
    </ul>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
